import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const PrehomeWrapper = styled.div`
  width: 100%;
  height: ${props => props.isMobile ? 'calc(100vh - 120px)' : 'calc(100vh - 90px)'};
  box-shadow: 0px 0px 10px 6px #DDD;
  display:flex;
  flex-direction: column;
  position:absolute;
  top: 80px;

  .photo {
    flex-grow: 1;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .banner {
    display:flex;
    flex-direction: row;
    height: 220px;

    .title {
      display:flex;
      flex-direction: column;
      justify-content: center;
      line-height: 0.8;
      font-size: 90px;
      z-index:2;
      padding-left: 10px;
      color : ${props => props.theme.color.primaryColor};
  
      .secret-count {
        font-weight: 600;
      }
      .text {
        display:block;
        font-weight: 500;
        letter-spacing: -3.5px;
        font-size: ${props => `${3.4*props.theme.font.primaryFont.scaling/100}rem`};;
        padding-right: 8px;
      }
    }

    .banner-content {
      padding-top: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      .text {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;     
        justify-content: center;
        font-size: ${props => `${32*props.theme.font.primaryFont.scaling/100}px`};;
        line-height: 38px;
        text-align: center;
      }
      .buttons {
        display:flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        height: 63px;
        .button {
          cursor : pointer;
          display:flex;
          align-items: center;
          justify-content: center;
          width: 42%;
          height: 44px;
          border-radius: 22px;
          background-color: ${props => props.theme.color.primaryColor};
          color: white;
          font-size: ${props => `${21*props.theme.font.primaryFont.scaling/100}px`};;
        }
      }
    }
  }

@media (max-width: ${isMobileBreakpoint}px) {
  top: 70px;
  box-shadow: 0px 0px 20px 12px #DDD;

  .photo {
    padding: 0;
  }

  .banner {
    flex-direction: column;
    height: 410px;
    justify-content: flex-start;
    .title {
      flex-direction: row;
      align-items: center;
      margin-top: 32px;
      .secret-count {
        margin-right: 8px;
      }
      .secret-text {
        padding-bottom: 6px;
        .text {
          font-size: ${props => `${46*props.theme.font.primaryFont.scaling/100}px`};;
        }
      }
    }
    .banner-content {
      flex-grow: 0;
      padding-top: 0;
      .text {
        margin-top: 8px;
        margin-bottom: 32px;
        font-size: ${props => `${24*props.theme.font.primaryFont.scaling/100}px`};;
      }
      .buttons {
        flex-direction: column;
        align-items: center;
        .button {
          width: 90%;
          margin-top: 16px;
          font-size: ${props => `${19*props.theme.font.primaryFont.scaling/100}px`};;
        }
      }
    }
  }
}

`;

export default PrehomeWrapper;