import React, { useContext } from 'react';
import HomeView from 'ToolboxComponents/webapp-light/pages/home-view/home-view';
import { WhiteLabelContext } from "ToolboxUtils/web/context/context";


const Home = props => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  return (
    <HomeView nbSecrets={whiteLabel.nbSecrets} secretSiteUrl={whiteLabel.secretSiteUrl}/>
  );
}

export default Home;