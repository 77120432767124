import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, WebappContext, WhiteLabelContext, AccountContext } from 'ToolboxUtils/web/context/context';
import PrehomeWrapper from 'ToolboxComponents/webapp-light/pages/home-view/components/prehome/prehome.style';
import Text from 'ToolboxComponents/commons/texts/text/text';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';


const Prehome = props => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [isMobile] = useState(isMobileView());
  const [modalSignIn, setModalSignIn] = useContext(WebappContext).usePath('modals.signIn');
  const [modalSignUp, setModalSignUp] = useContext(WebappContext).usePath('modals.signUp');
  const [modalAddSecret, setModalAddSecret] = useContext(WebappContext).usePath('modals.addSecret');

  const [account, setAccount] = useContext(AccountContext).usePath();

  return (
    <PrehomeWrapper theme={theme} isMobile={isMobile}>
      <div className='container photo'>
        <img src={whiteLabel.webappLight.prehomeImage}/>
      </div>
      <div className='container banner'>
        <h1 className='title'>
            <span className='secret-count'>
              <Text
                path={`page.homepage.prehomeNbSecrets`}
                data={{ nbSecrets: whiteLabel.nbSecrets }}
              />
            </span>
            <span className='secret-text'>
              <span className='text'>
                <Text path={`page.homepage.prehomeTitle1`} />
              </span>
              <span className='text'>
                <Text path={`page.homepage.prehomeTitle2`} />
              </span>
            </span>
        </h1>
        <div className='banner-content'>
          <div className='text'>
            Vous avez la chance {isMobile && <br />}de vivre ici toute l'année, <br /> partagez vos secrets locaux
          </div>
          <div className='buttons'>
            {account ?
             <div className='button signup' onClick={() => {setModalAddSecret(true)}}>CHUCHOTER UN SECRET</div>
            :
            <>
            <div className='button signup' onClick={() => {setModalSignUp({opened: true, from: 'home'})}}>CRÉER UN COMPTE GRATUIT</div>
            <div className='button signin' onClick={() => {setModalSignIn({opened: true, from: 'home'})}}>SE CONNECTER</div>
            </>
            }
          </div>
        </div>
      </div>
    </PrehomeWrapper>
  );
};


export default Prehome;