import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const HomeViewWrapper = styled.div`
.share {
  margin-top: calc(100vh - 90px);
  margin-bottom: 110px;
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    hr {
      position: absolute;
      z-index: 1;
      top: 122px;
      background: black;
      width: 100%;
    }
    .title {
      position: absolute;
      z-index: 2;
      top: 92px;
      background: white;
      width: 77%;
      height: 80px;
      text-align: center;
      h2 {
        font-weight: bold;
        margin-bottom: 0;
      }
      span {
        font-family: ${props => props.theme.font.secondaryFont.name};;
        font-size: ${props => `${31*props.theme.font.primaryFont.scaling/100}px`};;
        color:  ${props => props.theme.color.primaryColor};
      }
    }
    p {
      margin-top: 218px;
      width: 70%;
      font-size: ${props => `${21*props.theme.font.primaryFont.scaling/100}px`};;
      text-align: center;
      line-height: 27px;
      a {
        color:  ${props => props.theme.color.primaryColor};
      }
    }
  }
}


.local {
  background: ${props => props.theme.color.primaryColor};
  color: white;
  padding-top: 50px;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 42px;
    h2 {
      font-family: ${props => props.theme.font.secondaryFont.name};
      font-size: ${props => `${36*props.theme.font.primaryFont.scaling/100}px`};;
    }
    p {
      margin-top: 25px;
      width: 70%;
      font-size: ${props => `${21*props.theme.font.primaryFont.scaling/100}px`};;
      text-align: center;
      line-height: 27px;
    }
    img {
      width: 90%;
      margin-top: 20px;
    }
  }
}

@media (max-width: ${isMobileBreakpoint}px) {
  
  .share {
    margin-top: calc(100vh - 20px);
    .container {
      .title {
        position: static;
        width: 90%;
        h2 {
          font-size: ${props => `${1.8*props.theme.font.primaryFont.scaling/100}rem`};;
        }
        span {
          font-size: ${props => `${29*props.theme.font.primaryFont.scaling/100}px`};;
          padding-top: 10px;
          line-height: 33px;
          display: block;
        }
      }
      p {
        margin-top: 114px;
        width: 90%;
        font-size: ${props => `${20*props.theme.font.primaryFont.scaling/100}px`};;
        a {
          overflow:hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }

  .local {
    .container {
      p {
        width: 90%;
        font-size: ${props => `${20*props.theme.font.primaryFont.scaling/100}px`};;
      }
      img {
        width: 100%;
      }
    }
  }

}



`;

export default HomeViewWrapper;